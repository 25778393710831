import React, { useState } from "react";
import { Card, CardBody, Row, Col, Label, Input, Button, Spinner } from "reactstrap";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FeatherIcon from "feather-icons-react";

const api = new APIClient();

const Cadastrar = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    categoria: "regras",
    titulo: "",
    texto: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEditorChange = (event, editor) => {
    setFormData(prev => ({
      ...prev,
      texto: editor.getData()
    }));
  };

  const handleSubmit = async () => {
    if (!formData.titulo.trim() || !formData.texto.trim()) {
      toast.error("Por favor, preencha todos os campos.");
      return;
    }

    setLoading(true);
    const response = await api.post("/informacoes", formData);
    
    if (response.sucesso) {
      toast.success(response.mensagem || "Informação cadastrada com sucesso!");
      setFormData({
        categoria: "regras",
        titulo: "",
        texto: "",
      });
      if (onSuccess) onSuccess();
    } else {
      toast.error(response.mensagem || "Erro ao cadastrar informação.");
    }
    setLoading(false);
  };

  const editorConfiguration = {
    language: "pt-br",
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "color",
      "|",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "blockQuote",
      "undo",
      "redo"
    ]
  };

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">Cadastrar Nova Informação</h4>
        <Row>
          <Col md="6" className="mb-3">
            <Label className="form-label" for="titulo">Título</Label>
            <Input
              type="text"
              id="titulo"
              name="titulo"
              value={formData.titulo}
              onChange={handleInputChange}
              placeholder="Digite o título"
            />
          </Col>
          <Col md="6" className="mb-3">
            <Label className="form-label" for="categoria">Categoria</Label>
            <Input
              type="select"
              id="categoria"
              name="categoria"
              value={formData.categoria}
              onChange={handleInputChange}
            >
              <option value="regras">Regras</option>
              <option value="utilidades">Utilidades</option>
            </Input>
          </Col>
          <Col md="12" className="mb-3">
            <Label className="form-label" for="texto">Texto</Label>
            <div className="ck-editor-reverse">
              <CKEditor
                editor={ClassicEditor}
                data={formData.texto}
                config={editorConfiguration}
                onChange={handleEditorChange}
              />
            </div>
          </Col>
        </Row>
        <div className="text-end mt-3">
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner size="sm" className="me-2">
                  Carregando...
                </Spinner>
                Cadastrando
              </>
            ) : (
              <>
                <FeatherIcon icon="save" className="me-2" size={14} />
                Cadastrar
              </>
            )}
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default Cadastrar;
