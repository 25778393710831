import React, { useState, useEffect } from "react";
import { 
  Accordion, 
  AccordionItem, 
  Card, 
  CardBody, 
  Collapse, 
  Spinner, 
  Button, 
  Input, 
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import classnames from "classnames";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const api = new APIClient();

const DeleteModal = ({ isOpen, toggle, onConfirm, loading }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        Confirmar Exclusão
      </ModalHeader>
      <ModalBody>
        Tem certeza que deseja excluir esta utilidade? Esta ação não pode ser desfeita.
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle} disabled={loading}>
          Cancelar
        </Button>
        <Button color="danger" onClick={onConfirm} disabled={loading}>
          {loading ? (
            <>
              <Spinner size="sm" className="me-2">
                Carregando...
              </Spinner>
              Excluindo
            </>
          ) : (
            <>
              <FeatherIcon icon="trash-2" className="me-2" size={14} />
              Excluir
            </>
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const EditForm = ({ utilidade, onSave, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    titulo: utilidade.titulo,
    texto: utilidade.texto,
    categoria: "utilidades"
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEditorChange = (event, editor) => {
    setFormData(prev => ({
      ...prev,
      texto: editor.getData()
    }));
  };

  const handleSubmit = async () => {
    if (!formData.titulo.trim() || !formData.texto.trim()) {
      toast.error("Por favor, preencha todos os campos.");
      return;
    }

    setLoading(true);
    const response = await api.put(`/informacoes/${utilidade.id}`, formData);
    
    if (response.sucesso) {
      toast.success(response.mensagem || "Informação atualizada com sucesso!");
      onSave(response.dados);
    } else {
      toast.error(response.mensagem || "Erro ao atualizar informação.");
    }
    setLoading(false);
  };

  const editorConfiguration = {
    language: "pt-br",
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "color",
      "|",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "blockQuote",
      "undo",
      "redo"
    ]
  };

  return (
    <div className="mb-3 p-3 bg-light rounded">
      <div className="mb-3">
        <Label className="form-label" for="titulo">Título</Label>
        <Input
          type="text"
          id="titulo"
          name="titulo"
          value={formData.titulo}
          onChange={handleInputChange}
          placeholder="Digite o título"
        />
      </div>
      <div className="mb-3">
        <Label className="form-label" for="texto">Texto</Label>
        <div className="ck-editor-reverse">
          <CKEditor
            editor={ClassicEditor}
            data={formData.texto}
            config={editorConfiguration}
            onChange={handleEditorChange}
          />
        </div>
      </div>
      <div className="text-end mt-3">
        <Button
          color="secondary"
          className="me-2"
          onClick={onCancel}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner size="sm" className="me-2">
                Carregando...
              </Spinner>
              Salvando
            </>
          ) : (
            <>
              <FeatherIcon icon="save" className="me-2" size={14} />
              Salvar
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

const Utilitarios = ({ isActive }) => {
  const [utilitarios, setUtilitarios] = useState([]);
  const [activeIds, setActiveIds] = useState({});
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [deleteModal, setDeleteModal] = useState({ open: false, utilidadeId: null });
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    const authUser = api.getLoggedinUser();
    setIsAdmin(authUser?.data?.id === 1);
  }, []);

  useEffect(() => {
    if (isActive) {
      fetchUtilitarios();
    }
  }, [isActive]);

  const fetchUtilitarios = async () => {
    setLoading(true);
    const response = await api.get("/informacoes?categoria=utilidades");

    if (response.sucesso && response.dados) {
      setUtilitarios(response.dados);
      const initialActiveState = {};
      response.dados.forEach((util, index) => {
        initialActiveState[index] = index === 0;
      });
      setActiveIds(initialActiveState);
    } else {
      toast.error(response.mensagem || "Erro ao carregar as utilidades.");
    }
    setLoading(false);
  };

  const toggleAccordion = (index) => {
    setActiveIds((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleSave = (updatedUtilidade) => {
    setUtilitarios(prev => prev.map(util => 
      util.id === updatedUtilidade.id ? updatedUtilidade : util
    ));
    setEditingId(null);
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    const response = await api.delete(`/informacoes/${deleteModal.utilidadeId}`);
    
    if (response.sucesso) {
      toast.success(response.mensagem || "Utilidade excluída com sucesso!");
      setUtilitarios(prev => prev.filter(util => util.id !== deleteModal.utilidadeId));
      setDeleteModal({ open: false, utilidadeId: null });
    } else {
      toast.error(response.mensagem || "Erro ao excluir a utilidade.");
    }
    setDeleteLoading(false);
  };

  if (loading) {
    return (
      <Card>
        <CardBody className="text-center p-5">
          <Spinner color="primary">
            Carregando...
          </Spinner>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">Utilidades</h4>
        {utilitarios.length === 0 ? (
          <div className="text-center text-muted">
            Nenhuma utilidade encontrada.
          </div>
        ) : (
          <Accordion
            className="custom-accordionwithicon accordion-border-box"
            id="accordionBordered"
          >
            {utilitarios.map((util, index) => (
              <AccordionItem key={util.id}>
                <h2 className="accordion-header" id={`accordionbordered${index}`}>
                  <button
                    className={classnames("accordion-button", {
                      collapsed: !activeIds[index],
                    })}
                    type="button"
                    onClick={() => toggleAccordion(index)}
                    style={{ cursor: "pointer" }}
                  >
                    {util.titulo}
                  </button>
                </h2>
                <Collapse
                  isOpen={activeIds[index]}
                  className="accordion-collapse"
                  id={`accor_borderedExamplecollapse${index}`}
                >
                  <div className="accordion-body">
                    {editingId === util.id ? (
                      <EditForm 
                        utilidade={util}
                        onSave={handleSave}
                        onCancel={() => setEditingId(null)}
                      />
                    ) : (
                      <>
                        <div dangerouslySetInnerHTML={{ __html: util.texto }} />
                        {isAdmin && (
                          <div className="text-end mt-3">
                            <Button
                              color="danger"
                              size="sm"
                              className="me-2"
                              onClick={() => setDeleteModal({ open: true, utilidadeId: util.id })}
                            >
                              <FeatherIcon icon="trash-2" className="me-1" size={14} />
                              Excluir
                            </Button>
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => setEditingId(util.id)}
                            >
                              <FeatherIcon icon="edit-2" className="me-1" size={14} />
                              Editar
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Collapse>
              </AccordionItem>
            ))}
          </Accordion>
        )}

        <DeleteModal
          isOpen={deleteModal.open}
          toggle={() => setDeleteModal({ open: false, utilidadeId: null })}
          onConfirm={handleDelete}
          loading={deleteLoading}
        />
      </CardBody>
    </Card>
  );
};

export default Utilitarios;
