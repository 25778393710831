import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link, useParams, useNavigate } from "react-router-dom";
import logoLight from "../../assets/images/logo-light.png";
import axios from "axios";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";

const ResetSenha = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emailEnviado, setEmailEnviado] = useState("");

  const handleSolicitarReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/usuario/reset-senha`, {
        email,
      });
      setEmailEnviado(email);
      setSuccess(true);
    } catch (error) {
      toast.error(
        error.response?.data?.mensagem || "Ocorreu um erro ao resetar a senha."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleTrocarSenha = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      toast.error("A senha deve ter no mínimo 6 caracteres");
      return;
    }
    if (password !== passwordConfirmation) {
      toast.error("As senhas não conferem");
      return;
    }

    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/usuario/trocar-senha`, {
        token: uuid,
        password,
        password_confirmation: passwordConfirmation,
      });
      setSuccess(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      const status = error.response?.status;
      if (status === 400) {
        toast.error("Token inválido, expirado ou já utilizado");
      } else if (status === 404) {
        toast.error("Usuário não encontrado");
      } else if (status === 422) {
        toast.error("Senha inválida ou não confere");
      } else {
        toast.error("Ocorreu um erro ao alterar a senha");
      }
    } finally {
      setLoading(false);
    }
  };

  const renderSolicitarReset = () => (
    <>
      <div className="text-center mt-2">
        <h5 className="text-primary">Resetar Senha</h5>
        <p className="text-muted">
          Digite seu email para receber o link de reset de senha. Você
          precisa ter uma conta cadastrada com um email válido.
        </p>
      </div>
      <div className="p-2 mt-4">
        <form onSubmit={handleSolicitarReset}>
          <div className="mb-3">
            <Label htmlFor="email" className="form-label">
              Email
            </Label>
            <Input
              type="email"
              className="form-control"
              id="email"
              placeholder="Digite seu email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength={100}
              required
            />
          </div>

          <div className="mt-4">
            <Button
              color="secondary"
              className="btn btn-primary w-100"
              type="submit"
              disabled={loading}
            >
              {loading && <Spinner size="sm" className="me-2" />}
              Enviar
            </Button>
          </div>

          <div className="mt-4 text-center">
            <p className="mb-0 text-muted">
              Lembrou sua senha?{" "}
              <Link
                to="/login"
                className="fw-semibold text-primary text-decoration-underline"
              >
                Voltar para o Login
              </Link>
            </p>
          </div>
        </form>
      </div>
    </>
  );

  const renderTrocarSenha = () => (
    <>
      <div className="text-center mt-2">
        <h5 className="text-primary">Nova Senha</h5>
        <p className="text-muted">
          Digite sua nova senha. Ela deve ter no mínimo 6 caracteres.
        </p>
      </div>
      <div className="p-2 mt-4">
        <form onSubmit={handleTrocarSenha}>
          <div className="mb-3">
            <Label htmlFor="password" className="form-label">
              Nova Senha
            </Label>
            <Input
              type="password"
              className="form-control"
              id="password"
              placeholder="Digite a nova senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              minLength={6}
              required
            />
          </div>

          <div className="mb-3">
            <Label htmlFor="password_confirmation" className="form-label">
              Confirme a Nova Senha
            </Label>
            <Input
              type="password"
              className="form-control"
              id="password_confirmation"
              placeholder="Confirme a nova senha"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              minLength={6}
              required
            />
          </div>

          <div className="mt-4">
            <Button
              color="secondary"
              className="btn btn-primary w-100"
              type="submit"
              disabled={loading}
            >
              {loading && <Spinner size="sm" className="me-2" />}
              Alterar Senha
            </Button>
          </div>
        </form>
      </div>
    </>
  );

  const renderSuccess = () => (
    <div className="text-center p-4">
      <div className="mb-4">
        <FeatherIcon
          icon="check-circle"
          className="text-success"
          size={50}
        />
      </div>
      {uuid ? (
        <>
          <h4 className="text-primary mb-3">Senha Alterada!</h4>
          <p className="text-muted mb-4">
            Sua senha foi alterada com sucesso. Você será redirecionado para a página de login em instantes...
          </p>
        </>
      ) : (
        <>
          <h4 className="text-primary mb-3">Email Enviado!</h4>
          <p className="text-muted mb-4">
            Um link para reset de senha foi enviado para {emailEnviado}. Por favor, verifique sua caixa de entrada.
          </p>
          <Link
            to="/login"
            className="btn btn-primary w-100"
          >
            Voltar para o Login
          </Link>
        </>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="40" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4 card-bg-fill">
                  <CardBody className="p-4">
                    {success ? (
                      renderSuccess()
                    ) : uuid ? (
                      renderTrocarSenha()
                    ) : (
                      renderSolicitarReset()
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default ResetSenha;
