import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";
import Revendedores from "../pages/Revendedores";
import Clientes from "../pages/Clientes";
import ClientesOnline from "../pages/ClientesOnline";
import Avisos from "../pages/Avisos";
import Testes from "../pages/Testes";
import Tickets from "../pages/Tickets";
import Logs from "../pages/Logs";
import PedidosMercadoPago from "../pages/PedidosMercadoPago";
import Pedidos from "../pages/Pedidos";
import Downloads from "../pages/Downloads";
import Dominios from "../pages/Dominios";
import Informacoes from "../pages/Informacoes";
import UtmDevices from "../pages/UtmDevices";
import UtmDevicesPro from "../pages/UtmDevicesPro";
import MagDevices from "../pages/MagDevices";
import Ferramentas from "../pages/Ferramentas";
import FerramentasAdmin from "../pages/FerramentasAdmin";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Solicitacoes from "../pages/Solicitacoes";
import VideoTutoriais from "../pages/VideoTutoriais";
import MuralAnuncios from "../pages/MuralAnuncios";
import MuralAnunciosBlog from "../pages/MuralAnunciosBlog";
import Conteudos from "../pages/Conteudos";
import Novidades from "../pages/Novidades";
import AbrirTicket from "../pages/Authentication/AbrirTicket";

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ResetSenha from "../pages/Authentication/ResetSenha";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

import { APIClient } from "../helpers/api_helper";
import Execucoes from "../pages/Execucoes";
const apiClient = new APIClient();
const userData = apiClient.getLoggedinUser();

const authProtectedRoutes = [
  { path: "/principal", component: <DashboardEcommerce /> },
  { path: "/index", component: <DashboardEcommerce /> },
  { path: "/revendedores", component: <Revendedores /> },
  { path: "/revendedores/:user_id", component: <Revendedores /> },
  { path: "/avisos", component: <Avisos /> },
  { path: "/clientes", component: <Clientes /> },
  { path: "/clientes/:user_id", component: <Clientes /> },
  { path: "/clientes-online", component: <ClientesOnline /> },
  { path: "/testes", component: <Testes /> },
  { path: "/tickets-suporte", component: <Tickets /> },
  { path: "/logs", component: <Logs /> },
  { path: "/execucoes", component: <Execucoes /> },
  { path: "/pedidos", component: <Pedidos /> },
  { path: "/pedidos-mercado-pago", component: <PedidosMercadoPago /> },
  { path: "/logs/:user_id", component: <Logs /> },
  { path: "/execucoes/:user_id", component: <Execucoes /> },
  { path: "/utm-devices", component: <UtmDevices /> },
  { path: "/utm-devices-pro", component: <UtmDevicesPro /> },
  { path: "/mag-devices", component: <MagDevices /> },
  { path: "/loja", component: <Downloads /> },
  { path: "/dominios", component: <Dominios /> },
  { path: "/informacoes", component: <Informacoes /> },
  { path: "/video-tutoriais", component: <VideoTutoriais /> },
  { path: "/ferramentas", component: <Ferramentas /> },
  { path: "/conteudos", component: <Conteudos /> },
  { path: "/novidades", component: <Novidades /> },
  {
    path: "/ferramentas-admin",
    component: userData?.data?.id === 1 ? <FerramentasAdmin /> : <Basic404 />,
  },
  {
    path: "/mural-anuncios-blog",
    component: <MuralAnunciosBlog categoria="novidades" />,
  },
  {
    path: "/mural-jogos",
    component: <MuralAnunciosBlog categoria="jogos" />,
  },
  {
    path: "/mural-novos-conteudos",
    component: <MuralAnunciosBlog categoria="novos_conteudos" />,
  },
  {
    path: "/mural-anuncios",
    component:
      userData?.data?.id === 1 || userData?.data?.id === 2169799 ? (
        <MuralAnuncios />
      ) : (
        <Basic404 />
      ),
  },
  { path: "/solicitacoes", component: <Solicitacoes /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/principal" />,
  },
  { path: "*", component: <Basic404 /> }, //<Navigate to="/principal" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/reset-senha", component: <ResetSenha /> },
  { path: "/reset-senha/:uuid", component: <ResetSenha /> },
  { path: "/abrir-ticket", component: <AbrirTicket /> },
];

export { authProtectedRoutes, publicRoutes };
