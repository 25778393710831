import React, { useEffect, useState, Fragment } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  ListGroup,
  ListGroupItem,
  Button
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import "./Novidades.css";
import { APIClient } from "../../helpers/api_helper";
import classnames from "classnames";

const apiClient = new APIClient();

const Novidades = () => {
  document.title = "Novidades | Dark Store";

  const [novidades, setNovidades] = useState({
    filmes: [],
    series: [],
    episodios: [],
    doramas: [],
    novelas: [],
    shows: [],
    animes: [],
    canais: [],
    canais_adultos: []
  });
  const [loading, setLoading] = useState(true);
  const [periodoDias, setPeriodoDias] = useState(3);
  const [activeTab, setActiveTab] = useState("filmes_shows");

  const fetchNovidades = async (dias) => {
    setLoading(true);
    try {
      const response = await apiClient.get(`/novidades`, { dias });
      if (response.sucesso) {
        setNovidades(response.dados);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401"))
          if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (!response.sucesso && (!response.responseCode || response.responseCode === 401)) {
        await checkLastLogin();
      }
    } catch (error) {
      console.error("Erro ao buscar novidades:", error);
      toast.error("Erro ao carregar novidades");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNovidades(periodoDias);
  }, [periodoDias]);

  const getEmoticon = (tipo) => {
    switch (tipo) {
      case 'filme':
      case 'show':
        return '🎬';
      case 'serie':
      case 'episodio':
      case 'anime':
        return '📺';
      case 'novela':
      case 'dorama':
        return '🎭';
      case 'canal':
        return '📡';
      case 'canal_adulto':
        return '🔞';
      default:
        return '';
    }
  };

  const copiarComoTexto = (titulo, items, tipo) => {
    const emoticon = getEmoticon(tipo);
    const texto = `${titulo} ${emoticon}\n${items.map(item => `- ${item.titulo}`).join('\n')}`;
    
    navigator.clipboard.writeText(texto).then(() => {
      toast.success('Texto copiado para a área de transferência!');
    }).catch(() => {
      toast.error('Erro ao copiar texto');
    });
  };

  const renderCardHeader = (titulo, items, tipo) => {
    return (
      <div className="card-header-flex">
        <h5 className="card-title mb-3">{titulo}</h5>
        <Button
          className="btn-sm btn-soft-secondary d-flex align-items-center gap-1 mb-3"
          onClick={() => copiarComoTexto(titulo, items, tipo)}
          title="Copiar como texto"
        >
          <FeatherIcon icon="copy" size={14} />
          Copiar como texto
        </Button>
      </div>
    );
  };

  const renderConteudo = () => {
    if (loading) {
      return (
        <div className="text-center p-5">
          <Spinner color="primary" className="me-2">
            Carregando...
          </Spinner>
        </div>
      );
    }

    if (activeTab === "filmes_shows") {
      return (
        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                {renderCardHeader("Últimos Filmes", novidades.filmes, "filme")}
                <ListGroup flush>
                  {novidades.filmes.map((filme, index) => (
                    <ListGroupItem key={`filme-${index}`}>
                      {filme.titulo}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>

          <Col md={6}>
            <Card>
              <CardBody>
                {renderCardHeader("Últimos Shows", novidades.shows, "show")}
                <ListGroup flush>
                  {novidades.shows.map((show, index) => (
                    <ListGroupItem key={`show-${index}`}>
                      {show.titulo}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      );
    }

    if (activeTab === "series_animes") {
      return (
        <Row>
          <Col md={4}>
            <Card>
              <CardBody>
                {renderCardHeader("Últimas Séries", novidades.series, "serie")}
                <ListGroup flush>
                  {novidades.series.map((serie, index) => (
                    <ListGroupItem key={`serie-${index}`}>
                      {serie.titulo}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <CardBody>
                {renderCardHeader("Últimos Episódios", novidades.episodios, "episodio")}
                <ListGroup flush>
                  {novidades.episodios.map((episodio, index) => (
                    <ListGroupItem key={`episodio-${index}`}>
                      {episodio.titulo}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <CardBody>
                {renderCardHeader("Últimos Animes", novidades.animes, "anime")}
                <ListGroup flush>
                  {novidades.animes.map((anime, index) => (
                    <ListGroupItem key={`anime-${index}`}>
                      {anime.titulo}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      );
    }

    if (activeTab === "novelas_doramas") {
      return (
        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                {renderCardHeader("Últimas Novelas", novidades.novelas, "novela")}
                <ListGroup flush>
                  {novidades.novelas.map((novela, index) => (
                    <ListGroupItem key={`novela-${index}`}>
                      {novela.titulo}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>

          <Col md={6}>
            <Card>
              <CardBody>
                {renderCardHeader("Últimos Doramas", novidades.doramas, "dorama")}
                <ListGroup flush>
                  {novidades.doramas.map((dorama, index) => (
                    <ListGroupItem key={`dorama-${index}`}>
                      {dorama.titulo}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col md={6}>
          <Card>
            <CardBody>
              {renderCardHeader("Últimos Canais", novidades.canais, "canal")}
              <ListGroup flush>
                {novidades.canais.map((canal, index) => (
                  <ListGroupItem key={`canal-${index}`}>
                    {canal.titulo}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </CardBody>
          </Card>
        </Col>

        <Col md={6}>
          <Card>
            <CardBody>
              {renderCardHeader("Últimos Canais Adultos", novidades.canais_adultos, "canal_adulto")}
              <ListGroup flush>
                {novidades.canais_adultos.map((canal, index) => (
                  <ListGroupItem key={`canal-adulto-${index}`}>
                    {canal.titulo}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Novidades" pageTitle="Marketing" />

          <Nav pills className="nav nav-pills arrow-navtabs nav-success bg-light mb-3">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === "filmes_shows" })}
                onClick={() => setActiveTab("filmes_shows")}
              >
                <FeatherIcon icon="film" size={16} className="me-1" />
                Filmes / Shows
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === "series_animes" })}
                onClick={() => setActiveTab("series_animes")}
              >
                <FeatherIcon icon="tv" size={16} className="me-1" />
                Séries / Animes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === "novelas_doramas" })}
                onClick={() => setActiveTab("novelas_doramas")}
              >
                <FeatherIcon icon="film" size={16} className="me-1" />
                Novelas / Doramas
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === "canais" })}
                onClick={() => setActiveTab("canais")}
              >
                <FeatherIcon icon="tv" size={16} className="me-1" />
                Canais
              </NavLink>
            </NavItem>
          </Nav>

          <Nav pills className="nav nav-pills arrow-navtabs nav-info mb-3">
          {/* {[3, 7, 15, 30].map((dias) => ( */}
            {[3, 7].map((dias) => (
              <NavItem key={dias}>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: periodoDias === dias })}
                  onClick={() => setPeriodoDias(dias)}
                >
                  <FeatherIcon icon="clock" size={16} className="me-1" />
                  Últimos {dias} dias
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          {renderConteudo()}
        </Container>
      </div>
    </Fragment>
  );
};

export default Novidades;
