import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Paginacao from "../../Components/Common/Paginacao";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { Link, useParams } from "react-router-dom";

import SearchOption from "./SearchOption";
import ModalEditar from "./ModalEditar";
import ModalBloquear from "./ModalBloquear";
import ModalProrrogar from "./ModalProrrogar";
import ModalApagar from "./ModalApagar";
import ModalRecriar from "./ModalRecriar";
import ModalAdicionarMeses from "./ModalAdicionarMeses";
import ModalAdicionarMes from "./ModalAdicionarMes";
import ModalDownload from "./ModalDownload";
import ModalNovo from "./ModalNovo";
import ModalMigrarPainel from "./ModalMigrarPainel";
import ModalConexoes from "./ModalConexoes";
import ModalMudarRevendedor from "./ModalMudarRevendedor";
import ModalLimparLixeira from "./ModalLimparLixeira";
import ModalAdicionarCreditosMassa from "./ModalAdicionarMesesMassa";
import ModalApagarMassa from "./ModalApagarMassa";

import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";

import classnames from "classnames";
import { APIClient } from "../../helpers/api_helper";
import Legendas from "./Legendas";

const apiClient = new APIClient();

const Clientes = () => {
  document.title = "Clientes | Dark Store";
  const { user_id } = useParams();

  const [modalEditar, setModalEditar] = useState(false);
  const [modalBloquear, setModalBloquear] = useState(false);
  const [modalProrrogar, setModalProrrogar] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);
  const [modalRecriar, setModalRecriar] = useState(false);
  const [modalAdicionarMeses, setModalAdicionarMeses] = useState(false);
  const [modalAdicionarMes, setModalAdicionarMes] = useState(false);
  const [modalDownload, setModalDownload] = useState(false);
  const [modalNovo, setModalNovo] = useState(false);
  const [modalMigrarPainel, setModalMigrarPainel] = useState(false);
  const [modalConexoes, setModalConexoes] = useState(false);
  const [modalMudarRevendedor, setModalMudarRevendedor] = useState(false);
  const [modalLimparLixeira, setModalLimparLixeira] = useState(false);
  const [modalAdicionarCreditosMassa, setModalAdicionarCreditosMassa] =
    useState(false);
  const [modalApagarMassa, setModalApagarMassa] = useState(false);

  const [cliente, setCliente] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);
  const [userData, setUserData] = useState(null);

  const [clientes, setClientes] = useState([]);
  const [status, setStatus] = useState("Todos");
  const [revendedor, setRevendedor] = useState(null);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [numeroDePaginas, setNumeroDePaginas] = useState(1);
  const [painelTipo, setPainelTipo] = useState("-1");
  const [totalDeClientes, setTotalDeClientes] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");
  const [orderBy, setOrderBy] = useState("expiracao");
  const [orderAsc, setOrderAsc] = useState(false);

  const [selecionados, setSelecionados] = useState([]);

  const limparFiltros = () => {
    setClientes([]);
    setStatus("Todos");
    setRevendedor(null);
    setItensPorPagina(50);
    setPaginaAtual(1);
    setPainelTipo("-1");
    setNumeroDePaginas(1);
    setTotalDeClientes(0);
    setTermoBusca("");
    setOrderBy("expiracao");
    setOrderAsc(false);
    setLoading(true);
    setLoadTable(true);
  };

  const handleSelectSingle = (e, cliente) => {
    if (e.target.checked) {
      setSelecionados((prevSelecionados) => [...prevSelecionados, cliente]);
    } else {
      setSelecionados((prevSelecionados) =>
        prevSelecionados.filter((c) => c.id !== cliente.id)
      );
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelecionados(clientes);
    } else {
      setSelecionados([]);
    }
  };

  const toggleModalEditar = useCallback(async (cli) => {
    setCliente(cli);
    setModalEditar(true);
  }, []);

  const toggleModalLimparLixeira = useCallback(() => {
    setModalLimparLixeira(!modalLimparLixeira);
  }, [modalLimparLixeira]);

  const toggleModalBloquear = useCallback(async (cli) => {
    setCliente(cli);
    setModalBloquear(true);
  }, []);

  const toggleModalProrrogar = useCallback(async (cli) => {
    setCliente(cli);
    setModalProrrogar(true);
  }, []);

  const toggleModalApagar = useCallback(async (cli) => {
    setCliente(cli);
    setModalApagar(true);
  }, []);

  const toggleModalRecriar = useCallback(async (cli) => {
    setCliente(cli);
    setModalRecriar(true);
  }, []);

  const toggleModalAdicionarMeses = useCallback(async (cli) => {
    setCliente(cli);
    setModalAdicionarMeses(true);
  }, []);

  const toggleModalMudarRevendedor = useCallback(async (cli) => {
    setCliente(cli);
    setModalMudarRevendedor(true);
  }, []);

  const toggleModalConexoes = useCallback(async (cli) => {
    setCliente(cli);
    setModalConexoes(true);
  }, []);

  const toggleModalAdicionarMes = useCallback(
    async (cli) => {
      setCliente(cli);
      setModalAdicionarMes(!modalAdicionarMes);
    },
    [modalAdicionarMes]
  );

  const toggleModalDownload = useCallback(
    async (cli) => {
      setCliente(cli);
      setModalDownload(!modalDownload);
    },
    [modalDownload]
  );

  const toggleModalNovo = useCallback(() => {
    setModalNovo(!modalNovo);
  }, [modalNovo]);

  const toggleModalMigrarPainel = useCallback(async (cli) => {
    setCliente(cli);
    setModalMigrarPainel(true);
  }, []);

  const arrowNavToggle = (tab) => {
    if (painelTipo !== tab) {
      setPainelTipo(tab);
      setPaginaAtual(1);
      setLoading(true);
    }
  };

  const converterData = (dataISO) => {
    if (dataISO) {
      const ano = dataISO.substring(0, 4);
      const mes = dataISO.substring(5, 7);
      const dia = dataISO.substring(8, 10);
      const hora = dataISO.substring(11, 13);
      const minuto = dataISO.substring(14, 16);

      return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
    }
    return "";
  };

  const tratarStatus = (cliente) => {
    if (cliente.status === 1) {
      const dataExpiracao = new Date(cliente.expiracao.replace(" ", "T"));
      const dataAtual = new Date();
      const diferenca = dataExpiracao - dataAtual;
      const dias = diferenca / (1000 * 60 * 60 * 24);
      if (dias < 0) {
        return "Esgotado";
      } else if (dias < 3) {
        return "Esgotando";
      } else {
        return "Ativo";
      }
    } else if (cliente.status === 2) {
      return "Bloqueado";
    }
  };

  const fetchRevendedor = useCallback(async (userId) => {
    const response = await apiClient.get(`/revendedores/${userId}`);
    if (response.sucesso) {
      setRevendedor(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchClientes = useCallback(
    async (
      statusCliente,
      itensPorPagina,
      paginaAtual,
      termoBusca,
      painelTipo,
      orderBy,
      orderAsc,
      userId
    ) => {
      const data = {
        status: statusCliente,
        itens_pagina: itensPorPagina,
        pagina: termoBusca === "" ? paginaAtual : 1,
        termo: termoBusca,
        painel_tipo: painelTipo,
        order_by: orderBy,
        order: orderAsc ? "asc" : "desc",
        user_id: userId,
      };

      setSelecionados([]);

      const response = await apiClient.post("/clientes/busca", data);
      if (response.sucesso) {
        const dados = response.dados;
        setClientes(dados.data);
        setNumeroDePaginas(dados.last_page);
        setTotalDeClientes(dados.total);
        if (userId) {
          await fetchRevendedor(userId);
        }
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401"))
          if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    },
    []
  );

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      const userDados = apiClient.getLoggedinUser();
      setUserData(userDados.data);
      await fetchClientes(
        status,
        itensPorPagina,
        paginaAtual,
        termoBusca,
        painelTipo,
        orderBy,
        orderAsc,
        user_id
      );
      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
      setLoadTable(false);
    };

    fetchData();
    setLoading(false);
  }, [
    loading,
    itensPorPagina,
    paginaAtual,
    termoBusca,
    status,
    painelTipo,
    orderBy,
    orderAsc,
    refresh,
  ]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Clientes" pageTitle="Grupos" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={2}>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                >
                  <option>Itens por Página</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={5}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setPaginaAtual}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
              <Col xl={3}>
                <select
                  className="form-select mt-1"
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setOrderBy("expiracao");
                    setOrderAsc(e.target.value === "Esgotando" ? true : false);
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={status}
                >
                  <option value="Todos">Todos</option>
                  <option value="Ativo">Ativos</option>
                  <option value="Esgotado">Esgotados</option>
                  <option value="Esgotando">Esgotando</option>
                  <option value="Bloqueado">Bloqueados</option>
                  <option value="Lixeira" className="text-danger">
                    Lixeira
                  </option>
                </select>
              </Col>
              <Col xl={2}>
                <button
                  type="button"
                  className="btn btn-soft-primary w-100 mt-1"
                  onClick={limparFiltros}
                >
                  <FeatherIcon icon="refresh-ccw" className="me-1" width="13" />
                  Limpar Filtros
                </button>
              </Col>
            </Row>
          </Card>
          <Legendas />
          <Nav
            pills
            className="nav nav-pills arrow-navtabs nav-success bg-light mb-3 mt-3"
          >
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: painelTipo === "-1",
                })}
                onClick={() => {
                  arrowNavToggle("-1");
                }}
              >
                Todos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: painelTipo === "1",
                })}
                onClick={() => {
                  arrowNavToggle("1");
                }}
              >
                IPTV
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: painelTipo === "0",
                })}
                onClick={() => {
                  arrowNavToggle("0");
                }}
              >
                P2P
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: painelTipo === "2",
                })}
                onClick={() => {
                  arrowNavToggle("2");
                }}
              >
                P2P Plus
              </NavLink>
            </NavItem>
          </Nav>
          {(selecionados.length > 0 || status === "Lixeira") && (
            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader className="d-flex justify-content-end">
                    {!revendedor && (
                      <div className="ms-auto">
                        {status === "Lixeira" ? (
                          <button
                            type="button"
                            className="btn btn-sm btn-soft-danger ms-2"
                            onClick={() => toggleModalLimparLixeira()}
                          >
                            <FeatherIcon icon="trash" width="13" />
                            Limpar Lixeira
                          </button>
                        ) : (
                          selecionados.length > 0 && (
                            <>
                              <button
                                type="button"
                                className="btn btn-sm btn-soft-primary me-2"
                                onClick={() =>
                                  setModalAdicionarCreditosMassa(true)
                                }
                                disabled={selecionados.length > 100}
                                title={
                                  selecionados.length > 100
                                    ? "Limite de 100 clientes para tarefas em massa"
                                    : null
                                }
                              >
                                <FeatherIcon icon="clock" width="13" />
                                Renovar ({selecionados.length})
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => setModalApagarMassa(true)}
                                disabled={selecionados.length > 100}
                                title={
                                  selecionados.length > 100
                                    ? "Limite de 100 clientes para tarefas em massa"
                                    : null
                                }
                              >
                                <FeatherIcon icon="trash" width="13" />
                                Apagar ({selecionados.length})
                              </button>
                            </>
                          )
                        )}
                      </div>
                    )}
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col xl={12}>
              <small className="text-muted text-left mb-2">
                {selecionados.length} clientes selecionados
              </small>
            </Col>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {revendedor
                      ? `Clientes de ${revendedor.nome} (${totalDeClientes})`
                      : `Seus Clientes (${totalDeClientes})`}
                  </h4>

                  {!revendedor && (
                    <div className="flex-shrink-0">
                      <button
                        type="button"
                        className="btn btn-sm btn-soft-secondary"
                        onClick={() => toggleModalNovo()}
                      >
                        <FeatherIcon icon="user-plus" width="13" /> Novo Cliente
                      </button>
                    </div>
                  )}
                </CardHeader>
                <CardBody>
                  {status === "Lixeira" && (
                    <div className="align-items-center mb-4 justify-content-between row text-center text-sm-start">
                      <small className="text-muted">
                        Para retirar um cliente da lixeira, renove ou prorrogue
                        ele.
                      </small>
                    </div>
                  )}

                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col" style={{ width: "46px" }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="cardtableCheck"
                                    onChange={(e) => handleSelectAll(e)}
                                    checked={
                                      selecionados.length === clientes.length
                                    }
                                    ref={(el) =>
                                      el &&
                                      (el.indeterminate =
                                        selecionados.length > 0 &&
                                        selecionados.length < clientes.length)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="cardtableCheck"
                                  ></label>
                                </div>
                              </th>
                              <th scope="col" style={{ width: "15%" }}>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setOrderBy("nome");
                                    setOrderAsc(!orderAsc);
                                    setLoadTable(true);
                                    setLoading(true);
                                  }}
                                  className="text-warning"
                                >
                                  Nome
                                </Link>
                              </th>
                              <th scope="col" style={{ width: "11%" }}>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setOrderBy("externo_login");
                                    setOrderAsc(!orderAsc);
                                    setLoadTable(true);
                                    setLoading(true);
                                  }}
                                  className="text-warning"
                                >
                                  Usuário
                                </Link>
                              </th>
                              <th scope="col" style={{ width: "11%" }}>
                                Senha
                              </th>
                              <th scope="col" style={{ width: "11%" }}>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setOrderBy("expiracao");
                                    setOrderAsc(!orderAsc);
                                    setLoadTable(true);
                                    setLoading(true);
                                  }}
                                  className="text-warning"
                                >
                                  Expiração
                                </Link>
                              </th>
                              <th scope="col" style={{ width: "11%" }}>
                                Status
                              </th>
                              <th scope="col" style={{ width: "11%" }}>
                                Sistema
                              </th>
                              <th scope="col" style={{ width: "10%" }}>
                                Criador
                              </th>
                              <th scope="col" style={{ width: "20%" }}>
                                Ações
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {clientes.length !== 0 &&
                              clientes.map((cliente, index) => {
                                const clienteStatus = tratarStatus(cliente);
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={cliente}
                                          id={`cardtableCheck-${index}`}
                                          onChange={(e) =>
                                            handleSelectSingle(e, cliente)
                                          }
                                          checked={selecionados.includes(
                                            cliente
                                          )}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="cardtableCheck01"
                                        ></label>
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">
                                        <b>{cliente.nome}</b>
                                      </p>
                                      {cliente.email &&
                                        cliente.email !== "" && (
                                          <small className="text-muted">
                                            <b>E-mail:</b> {cliente.email}
                                          </small>
                                        )}

                                      {cliente.telefone &&
                                        cliente.telefone !==
                                          "(__) _____-____" &&
                                        cliente.telefone !== "" && (
                                          <small className="text-muted">
                                            <b>Tel:</b> {cliente.telefone}
                                          </small>
                                        )}
                                    </td>
                                    <td>
                                      <p className="mb-0">
                                        <b>{cliente.username || ""}</b>
                                      </p>

                                      {cliente.online === 1 &&
                                        (clienteStatus === "Ativo" ||
                                          clienteStatus === "Esgotando") && (
                                          <>
                                            <i className="mdi mdi-circle-medium text-success fs-15 align-middle"></i>
                                            <small className="text-muted">
                                              On-line
                                            </small>
                                          </>
                                        )}
                                    </td>
                                    <td>{cliente.externo_senha || ""}</td>
                                    <td>
                                      {converterData(cliente.expiracao)}
                                      <br />
                                      <small className="text-muted">
                                        Telas: {cliente.conexoes}
                                      </small>
                                    </td>
                                    <td>
                                      <span
                                        className={`badge bg-${
                                          clienteStatus === "Ativo"
                                            ? "success"
                                            : clienteStatus === "Esgotado"
                                            ? "danger"
                                            : clienteStatus === "Esgotando"
                                            ? "primary"
                                            : clienteStatus === "Bloqueado"
                                            ? "info"
                                            : "dark"
                                        }`}
                                      >
                                        {clienteStatus}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={`badge bg-${
                                          cliente.painel_tipo === 1
                                            ? "success"
                                            : cliente.painel_tipo === 0
                                            ? "secondary"
                                            : cliente.painel_tipo === 2
                                            ? "info"
                                            : "dark"
                                        }`}
                                      >
                                        {cliente.painel_tipo === 1
                                          ? "IPTV"
                                          : cliente.painel_tipo === 0
                                          ? "P2P"
                                          : cliente.painel_tipo === 2
                                          ? "P2P Plus"
                                          : ""}
                                      </span>
                                    </td>
                                    <td>{cliente.parent?.username}</td>
                                    <td>
                                      {user_id && userData?.id !== 1 ? (
                                        <small className="text-muted">
                                          Não há ações disponíveis
                                        </small>
                                      ) : (
                                        <>
                                          <Link
                                            to="#"
                                            className="text-danger"
                                            title="Download de lista"
                                            onClick={() => {
                                              toggleModalDownload(cliente);
                                            }}
                                          >
                                            <FeatherIcon
                                              icon="download"
                                              width="18"
                                              className="me-3"
                                            />
                                          </Link>
                                          <Link
                                            to="#"
                                            className="text-success"
                                            title="Editar"
                                            onClick={() =>
                                              toggleModalEditar(cliente)
                                            }
                                          >
                                            <FeatherIcon
                                              icon="edit"
                                              width="18"
                                              className="me-3"
                                            />
                                          </Link>
                                          <Link
                                            to="#"
                                            className={`text-${
                                              cliente.status === 2
                                                ? "danger"
                                                : "info"
                                            }`}
                                            title={
                                              cliente.status === 2
                                                ? "Desbloquear"
                                                : "Bloquear"
                                            }
                                            onClick={() => {
                                              toggleModalBloquear(cliente);
                                            }}
                                          >
                                            <FeatherIcon
                                              icon="lock"
                                              width="18"
                                              className="me-3"
                                            />
                                          </Link>
                                          {cliente.status !== 2 && (
                                            <>
                                              <Link
                                                to="#"
                                                className="text-primary"
                                                title="Renovar"
                                                onClick={() =>
                                                  toggleModalAdicionarMeses(
                                                    cliente
                                                  )
                                                }
                                              >
                                                <FeatherIcon
                                                  icon="user-plus"
                                                  width="18"
                                                  className="me-3"
                                                />
                                              </Link>
                                              <Link
                                                to="#"
                                                className="text-success"
                                                title="Mudar Revendedor"
                                                onClick={() =>
                                                  toggleModalMudarRevendedor(
                                                    cliente
                                                  )
                                                }
                                              >
                                                <FeatherIcon
                                                  icon="user"
                                                  width="18"
                                                  className="me-3"
                                                />
                                              </Link>
                                              {cliente.painel_tipo === 1 && (
                                                <Link
                                                  to="#"
                                                  className="text-info"
                                                  title="Telas"
                                                  onClick={() => {
                                                    toggleModalConexoes(
                                                      cliente
                                                    );
                                                  }}
                                                >
                                                  <FeatherIcon
                                                    icon="tv"
                                                    width="18"
                                                    className="me-3"
                                                  />
                                                </Link>
                                              )}
                                              <br />
                                              <br />
                                              <Link
                                                to="#"
                                                className="text-warning"
                                                title="Prorrogar Data"
                                                onClick={() =>
                                                  toggleModalProrrogar(cliente)
                                                }
                                              >
                                                <FeatherIcon
                                                  icon="calendar"
                                                  width="18"
                                                  className="me-3"
                                                />
                                              </Link>
                                              <Link
                                                to="#"
                                                className="text-secondary"
                                                title="Renovar 1 mês"
                                                onClick={() => {
                                                  toggleModalAdicionarMes(
                                                    cliente
                                                  );
                                                }}
                                              >
                                                <FeatherIcon
                                                  icon="rotate-cw"
                                                  width="18"
                                                  className="me-3"
                                                />
                                              </Link>
                                              <Link
                                                to="#"
                                                className="text-warning"
                                                title="Recriar / Reiniciar Conexões"
                                                onClick={() => {
                                                  toggleModalRecriar(cliente);
                                                }}
                                              >
                                                <FeatherIcon
                                                  icon="refresh-cw"
                                                  width="18"
                                                  className="me-3"
                                                />
                                              </Link>
                                              <Link
                                                to="#"
                                                title="Migrar para outro sistema"
                                                onClick={() => {
                                                  toggleModalMigrarPainel(
                                                    cliente
                                                  );
                                                }}
                                              >
                                                <FeatherIcon
                                                  icon="repeat"
                                                  width="18"
                                                  className="me-3"
                                                />
                                              </Link>
                                            </>
                                          )}
                                          {status !== "Lixeira" && (
                                            <Link
                                              to="#"
                                              className="text-danger"
                                              title={
                                                status !== "Lixeira"
                                                  ? "Apagar Cliente"
                                                  : "Excluir Cliente definitivamente"
                                              }
                                              onClick={() => {
                                                toggleModalApagar(cliente);
                                              }}
                                            >
                                              <FeatherIcon
                                                icon="trash-2"
                                                width="18"
                                              />
                                            </Link>
                                          )}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && clientes.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Nenhum cliente encontrado
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeClientes
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeClientes
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">{totalDeClientes}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDeClientes}
                          currentPage={paginaAtual}
                          setCurrentPage={setPaginaAtual}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ModalAdicionarCreditosMassa
            modalAdicionarMesesMassa={modalAdicionarCreditosMassa}
            setModalAdicionarMesesMassa={setModalAdicionarCreditosMassa}
            setRefresh={setRefresh}
            selecionados={selecionados}
          />

          <ModalApagarMassa
            modalApagarMassa={modalApagarMassa}
            setModalApagarMassa={setModalApagarMassa}
            setRefresh={setRefresh}
            selecionados={selecionados}
            tratarStatus={tratarStatus}
          />

          <ModalEditar
            modalEditar={modalEditar}
            setModalEditar={setModalEditar}
            setRefresh={setRefresh}
            clienteId={cliente?.id}
            converterData={converterData}
          />
          <ModalBloquear
            modalBloquear={modalBloquear}
            setModalBloquear={setModalBloquear}
            setRefresh={setRefresh}
            cliente={cliente}
          />
          <ModalProrrogar
            modalProrrogar={modalProrrogar}
            setModalProrrogar={setModalProrrogar}
            setRefresh={setRefresh}
            cliente={cliente}
          />
          <ModalApagar
            modalApagar={modalApagar}
            setModalApagar={setModalApagar}
            setRefresh={setRefresh}
            cliente={cliente}
          />
          <ModalRecriar
            modalRecriar={modalRecriar}
            setModalRecriar={setModalRecriar}
            setRefresh={setRefresh}
            cliente={cliente}
          />
          <ModalAdicionarMeses
            modalAdicionarMeses={modalAdicionarMeses}
            setModalAdicionarMeses={setModalAdicionarMeses}
            setRefresh={setRefresh}
            cliente={cliente}
            converterData={converterData}
          />
          <ModalMudarRevendedor
            modalMudarRevendedor={modalMudarRevendedor}
            setModalMudarRevendedor={setModalMudarRevendedor}
            setRefresh={setRefresh}
            cliente={cliente}
          />
          <ModalConexoes
            modalConexoes={modalConexoes}
            setModalConexoes={setModalConexoes}
            cliente={cliente}
            setRefresh={setRefresh}
          />

          <ModalAdicionarMes
            modalAdicionarMes={modalAdicionarMes}
            setModalAdicionarMes={setModalAdicionarMes}
            setRefresh={setRefresh}
            cliente={cliente}
          />
          <ModalDownload
            modalDownload={modalDownload}
            setModalDownload={setModalDownload}
            cliente={cliente}
          />
          <ModalNovo
            modalNovo={modalNovo}
            setModalNovo={setModalNovo}
            setRefresh={setRefresh}
          />
          <ModalMigrarPainel
            modalMigrarPainel={modalMigrarPainel}
            setModalMigrarPainel={setModalMigrarPainel}
            setRefresh={setRefresh}
            clienteId={cliente?.id}
          />
          <ModalLimparLixeira
            modalLimparLixeira={modalLimparLixeira}
            setModalLimparLixeira={setModalLimparLixeira}
            setRefresh={setRefresh}
            revendedor={revendedor}
          />
        </Container>
      </div>
    </Fragment>
  );
};

export default Clientes;
