import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link } from "react-router-dom";
import logoLight from "../../assets/images/logo-light.png";

import { useQuill } from "react-quilljs";

import axios from "axios";
import { toast } from "react-toastify";

const Login = (props) => {
  const { quillRef } = useQuill();
  const [assunto, setAssunto] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      const data = {
        username,
        password,
        assunto,
        mensagem: quillRef.current.firstChild.innerHTML,
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/tickets/esgotados`,
          data
        );
        
        toast.success(response.data?.mensagem || "Ticket aberto com sucesso!");
        quillRef.current.firstChild.innerHTML = "";
        setAssunto("");
      } catch (error) {
        console.error(error);
        toast.error(
          error?.response?.data?.mensagem ||
            error?.response?.data?.message ||
            "Ocorreu um erro ao enviar ticket."
        );
      } finally {
        setLoading(false);
      }
    },
    [username, password]
  );

  useEffect(() => {
    const dadosLogin = JSON.parse(localStorage.getItem("dadosLogin"));
    if (
      dadosLogin &&
      dadosLogin.username !== "" &&
      dadosLogin.passBase64 !== ""
    ) {
      setUsername(dadosLogin.username);
      setPassword(atob(dadosLogin.password));
    }
  }, []);

  document.title = "Logar | Dark Store";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="40" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={8}>
                <Card className="mt-4 card-bg-fill">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-secondary">Abrir Ticket</h5>
                      <p className="text-muted">
                        Abra um ticket sem precisar fazer login.
                      </p>
                    </div>
                    <Row className="p-2 mt-4">
                      <Col md={6} className="mb-3">
                        <Label htmlFor="username" className="form-label">
                          Usuário
                        </Label>
                        <Input
                          className="form-control"
                          id="username"
                          placeholder="Digite o usuário"
                          type="text"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </Col>

                      <Col md={6} className="mb-3">
                        <div className="float-end">
                          <Link to="/reset-senha" className="text-muted">
                            Esqueceu a senha?
                          </Link>
                        </div>
                        <Label className="form-label" htmlFor="password">
                          Senha
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={passwordShow ? "text" : "password"}
                            className="form-control pe-5"
                            placeholder="Digite a senha"
                            id="password"
                          />

                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            id="password-addon"
                            onClick={() => setPasswordShow(!passwordShow)}
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} className="mb-3">
                        <Label className="form-label" htmlFor="assunto">
                          Assunto
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="assunto"
                          placeholder="Assunto"
                          value={assunto}
                          onChange={(e) => {
                            setAssunto(e.target.value);
                          }}
                        />
                      </Col>
                      <Col md={12} className="mb-3">
                        <Label className="form-label" htmlFor="mensagem">
                          Mensagem
                        </Label>
                        <div className="snow-editor" style={{ height: 300 }}>
                          <div ref={quillRef} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div className="mt-4">
                        <Button
                          color="secondary"
                          disabled={loading}
                          className="btn btn-primary w-100"
                          onClick={(e) => handleSubmit(e)}
                        >
                          {loading ? (
                            <Spinner size="sm" className="me-2" />
                          ) : null}
                          Enviar
                        </Button>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Login;
