import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Paginacao from "../../Components/Common/Paginacao";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import { toast } from "react-toastify";
import {
  checkLastLogin,
  limparMensagem,
  converterData,
} from "../../Components/FunctionsRepository";
import ModalAviso from "../DashboardEcommerce/ModalAviso";
import ModalCompose from "./ModalCompose";
import ModalEditar from "./ModalEditar";
import ModalApagar from "./ModalApagar";
import ModalApagarOld from "./ModalApagarOld";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const Avisos = () => {
  document.title = "Avisos de painel | Dark Store";

  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalVer, setModalVer] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);
  const [modalApagarOld, setModalApagarOld] = useState(false);

  const [avisos, setAvisos] = useState([]);
  const [aviso, setAviso] = useState(null);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDeAvisos, setTotalDeAvisos] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");
  const [orderBy, setOrderBy] = useState("expiracao");
  const [orderAsc, setOrderAsc] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleVer = (aviso) => {
    setAviso(aviso);
    setModalVer(!modalVer);
  };
  const toggleEditar = (aviso) => {
    setAviso(aviso);
    setModalEditar(!modalEditar);
  };
  const toggleApagar = (aviso) => {
    setAviso(aviso);
    setModalApagar(!modalApagar);
  };
  const toggleApagarOld = () => setModalApagarOld(!modalApagarOld);

  const fetchAvisos = useCallback(
    async (itensPorPagina, paginaAtual, termoBusca, orderBy, orderAsc) => {
      const data = {
        itens_pagina: itensPorPagina,
        pagina: termoBusca === "" ? paginaAtual : 1,
        termo: termoBusca,
        order_by: orderBy,
        order: orderAsc ? "asc" : "desc",
        online_data: true,
      };

      const response = await apiClient.get("/avisos/admin");
      if (response.sucesso) {
        const dados = response.dados;
        setAvisos(dados);
        setTotalDeAvisos(dados.length);
        setItensPorPagina(dados.length);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401")) {
          if (response.mensagem !== "") toast.error(response.mensagem);
        } else {
          await checkLastLogin();
        }
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    },
    []
  );

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      await fetchAvisos(
        itensPorPagina,
        paginaAtual,
        termoBusca,
        orderBy,
        orderAsc
      );
      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
      setLoadTable(false);
    };

    if (refresh) fetchData();
    setLoading(false);
  }, [loading, refresh]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Avisos On-Line" pageTitle="Utiitários" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Avisos do Painel
                  </h4>

                  <div className="flex-shrink-0">
                    <button
                      className="btn btn btn-soft-info"
                      onClick={() => toggle()}
                      disabled={loading}
                    >
                      Novo Aviso
                    </button>
                    <button
                      className="btn btn btn-soft-danger ms-2"
                      onClick={() => toggleApagarOld()}
                      disabled={loading}
                    >
                      Apagar Avisos Antigos
                    </button>
                  </div>
                </CardHeader>
                <CardBody>
                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col" style={{ width: "20%" }}>
                                Assunto
                              </th>
                              <th scope="col" style={{ width: "50%" }}>
                                Mensagem
                              </th>
                              <th scope="col" style={{ width: "10%" }}>
                                Visto
                              </th>
                              <th scope="col" style={{ width: "20%" }}>
                                Ações
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {avisos.length !== 0 &&
                              avisos.map((aviso, index) => {
                                return (
                                  <tr key={index}>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">
                                        <small className="text-muted">
                                          #{aviso.id}
                                        </small>{" "}
                                        <b>{aviso.assunto}</b>
                                      </p>
                                      <small className="text-muted">
                                        {converterData(aviso.updated_at)}
                                      </small>
                                    </td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      {limparMensagem(aviso.mensagem)}
                                    </td>
                                    <td>{aviso.lidos}</td>
                                    <td>
                                      <button
                                        className="btn btn-soft-primary"
                                        onClick={() => toggleVer(aviso)}
                                      >
                                        Ver
                                      </button>
                                      {/* editar */}
                                      <button
                                        className="btn btn-soft-warning ms-2"
                                        onClick={() => toggleEditar(aviso)}
                                      >
                                        Editar
                                      </button>
                                      {/* apagar */}
                                      <button
                                        className="btn btn-soft-danger ms-2"
                                        onClick={() => toggleApagar(aviso)}
                                      >
                                        Apagar
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && avisos.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Nenhum aviso encontrado
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeAvisos
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeAvisos
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">{totalDeAvisos}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDeAvisos}
                          currentPage={paginaAtual}
                          setCurrentPage={setPaginaAtual}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ModalCompose
            modal={modal}
            toggle={toggle}
            loading={loading}
            setLoading={setLoading}
            setRefresh={setRefresh}
          />

          <ModalApagarOld
            modal={modalApagarOld}
            toggle={toggleApagarOld}
            loading={loading}
            setLoading={setLoading}
            setRefresh={setRefresh}
          />

          <ModalAviso modal={modalVer} toggle={toggleVer} aviso={aviso} />

          <ModalEditar
            modal={modalEditar}
            toggle={toggleEditar}
            aviso={aviso}
            loading={loading}
            setLoading={setLoading}
            setRefresh={setRefresh}
          />

          <ModalApagar
            modal={modalApagar}
            toggle={toggleApagar}
            aviso={aviso}
            loading={loading}
            setLoading={setLoading}
            setRefresh={setRefresh}
          />
        </Container>
      </div>
    </Fragment>
  );
};

export default Avisos;
