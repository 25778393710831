import React, { Fragment, useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import classnames from "classnames";
import { APIClient } from "../../helpers/api_helper";
import FeatherIcon from "feather-icons-react";

// Importando os componentes
import Regras from "./Regras";
import Utilitarios from "./Utilitarios";
import Cadastrar from "./Cadastrar";

const api = new APIClient();

const Informacoes = () => {
  document.title = "Informações | Dark Store";
  const [activeTab, setActiveTab] = useState("2");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const authUser = api.getLoggedinUser();
    if (authUser?.data?.id === 1) {
      setIsAdmin(true);
      // Se for admin, começamos pela aba de cadastro
      setActiveTab("0");
    }
  }, []);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleCadastroSuccess = () => {
    // Atualiza a aba atual para mostrar o novo item
    if (activeTab === "1" || activeTab === "2") {
      toggleTab(activeTab);
    }
  };

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Informações" pageTitle="Utilitários" />
          <Row>
            <Col lg={12}>
              <Card>
                <div className="card-body">
                  <Row className="g-4">
                    <Col lg={2}>
                      <Nav pills className="nav-pills-custom flex-column" role="tablist">
                        {isAdmin && (
                          <NavItem>
                            <NavLink
                              className={classnames({ active: activeTab === "0" })}
                              onClick={() => toggleTab("0")}
                              role="tab"
                            >
                              <FeatherIcon icon="plus-circle" className="me-1" size={14} />
                              Cadastrar
                            </NavLink>
                          </NavItem>
                        )}
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === "2" })}
                            onClick={() => toggleTab("2")}
                            role="tab"
                          >
                            <FeatherIcon icon="tool" className="me-1" size={14} />
                            Utilidades
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === "1" })}
                            onClick={() => toggleTab("1")}
                            role="tab"
                          >
                            <FeatherIcon icon="book" className="me-1" size={14} />
                            Regras
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                    <Col lg={10}>
                      <TabContent activeTab={activeTab}>
                        {isAdmin && (
                          <TabPane tabId="0" role="tabpanel">
                            <Cadastrar onSuccess={handleCadastroSuccess} />
                          </TabPane>
                        )}
                        <TabPane tabId="1" role="tabpanel">
                          <Regras isActive={activeTab === "1"} />
                        </TabPane>
                        <TabPane tabId="2" role="tabpanel">
                          <Utilitarios isActive={activeTab === "2"} />
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Informacoes;
