import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalAdicionarMesesMassa = ({
  modalAdicionarMesesMassa,
  setModalAdicionarMesesMassa,
  setRefresh,
  selecionados,
}) => {
  const [loading, setLoading] = useState(false);
  const [mesesAdicionar, setMesesAdicionar] = useState(null);

  const toggle = () => {
    setRefresh(true);
    setMesesAdicionar(null);
    setModalAdicionarMesesMassa(!modalAdicionarMesesMassa);
  };

  useEffect(() => {
    if (!mesesAdicionar && selecionados.length > 0) {
      let initialMeses = {};
      for (let i = 0; i < selecionados.length; i++) {
        initialMeses[selecionados[i].id] = "1";
      }
      setMesesAdicionar(initialMeses);
    }
  }, [modalAdicionarMesesMassa]);

  const converterData = (dataISO) => {
    if (dataISO) {
      const ano = dataISO.substring(0, 4);
      const mes = dataISO.substring(5, 7);
      const dia = dataISO.substring(8, 10);
      const hora = dataISO.substring(11, 13);
      const minuto = dataISO.substring(14, 16);

      return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
    }
    return "";
  };

  const adicionarLista = (id, meses) => {
    let novoMesesAdicionar = { ...mesesAdicionar } || {};
    novoMesesAdicionar[id] = meses;
    setMesesAdicionar(novoMesesAdicionar);
  };

  const calcularPreco = (cliente, meses) => {
    if (!cliente) return 0;
    if(!meses || meses === "") return 0;
    const conexoes = cliente?.painel_tipo === 0 ? 1 : cliente?.conexoes;
    const precoOperacao =
      parseInt(meses || "0") * conexoes * cliente?.painel?.preco_credito;

    return precoOperacao;
  };

  const calcularTotalCreditos = () => {
    return selecionados.reduce((total, cliente) => {
      const meses = mesesAdicionar?.[cliente.id] || "0";
      return total + calcularPreco(cliente, meses);
    }, 0);
  };

  const renovar = async () => {
    setLoading(true);

    const mesesTratados = Object.fromEntries(
      Object.entries(mesesAdicionar || {})
        .filter(([id, meses]) => meses !== "")
        .map(([id, meses]) => [id, parseInt(meses)])
    );

    const clientesComMeses = selecionados
      .filter((cliente) => {
        const meses = mesesAdicionar?.[cliente.id];
        return meses && meses !== "" && meses !== "0";
      })
      .map((cliente) => cliente.id);

    if (clientesComMeses.length === 0) {
      toast.error("Nenhum cliente com meses válidos para renovação.");
      setLoading(false);
      return;
    }

    const data = {
      meses: mesesTratados,
      clientes: clientesComMeses,
    };

    const response = await apiClient.put("/clientes/massa/renovar", data);
    if (response.sucesso) {
      toast.success(response.mensagem || "Meses adicionados com sucesso.");
      localStorage.removeItem("lastUpdated");
      toggle();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modalAdicionarMesesMassa}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Renovar Clientes em Massa
      </ModalHeader>
      <ModalBody className="text-left p-5">
        {!loading && (
          <div className="mt-4">
            {selecionados.length > 0 &&
              selecionados.map((cliente, index) => (
                <Row className="mt-3" key={index}>
                  <Col md="6">
                    <Label htmlFor="meses" className="form-label">
                      {cliente.username}
                      <small className="text-muted d-block">
                        Expiração: {converterData(cliente.expiracao)}
                      </small>
                    </Label>
                  </Col>
                  <Col md="6">
                    <Input
                      type="text"
                      className="form-control"
                      id="meses"
                      value={mesesAdicionar?.[cliente.id] || ""}
                      onChange={(e) =>
                        adicionarLista(
                          cliente.id,
                          e.target.value === ""
                            ? ""
                            : e.target.value.replace(/\D/g, "")
                        )
                      }
                      maxLength={8}
                    />
                    <small className="text-muted">
                      Custo:{" "}
                      {calcularPreco(
                        cliente,
                        mesesAdicionar?.[cliente.id] || ""
                      ).toFixed(2)}{" "}
                      crédito(s)
                    </small>
                  </Col>
                </Row>
              ))}
            <Row className="mt-4">
              <Col md="12">
                <div className="alert alert-info text-center">
                  <strong>Total de Créditos:</strong>{" "}
                  {calcularTotalCreditos().toFixed(2)} crédito(s)
                </div>
              </Col>
            </Row>
            <div className="hstack gap-2 justify-content-center mt-3">
              <Link
                to="#"
                className="btn btn-link link-success fw-medium"
                onClick={() =>
                  setModalAdicionarMesesMassa(!modalAdicionarMesesMassa)
                }
              >
                <i className="ri-close-line me-1 align-middle"></i> Fechar
              </Link>
              <Link
                to="#"
                className="btn btn-success"
                onClick={() => renovar()}
              >
                Renovar
              </Link>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalAdicionarMesesMassa;
