import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { NumericFormat } from "react-number-format";
import InputMask from "react-input-mask";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalEditar = ({
  modalEditar,
  setModalEditar,
  setRefresh,
  revendedor,
  converterData,
}) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [limiteTestes, setLimiteTestes] = useState("0");
  const [limiteRevendas, setLimiteRevendas] = useState("0");
  const [valorCobrado, setValorCobrado] = useState(0);
  const [observacao, setObservacao] = useState("");
  const [userDados, setUserDados] = useState(null);

  const formatEmail = (event) => {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }

    setEmail(value);
  };

  const editar = async () => {
    setLoading(true);

    const response = await apiClient.put(`/revendedores/${revendedor.id}`, {
      nome,
      email,
      telefone,
      limite_teste: limiteTestes === "" ? 0 : parseInt(limiteTestes),
      limite_usuarios: limiteRevendas === "" ? 0 : parseInt(limiteRevendas),
      valor_cobrado: valorCobrado,
      observacao,
    });

    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setNome("");
      setUsername("");
      setEmail("");
      setTelefone("");
      setLimiteTestes("0");
      setLimiteRevendas("0");
      setValorCobrado(0);
      setObservacao("");
      setRefresh(true);
      setModalEditar(!modalEditar);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const fetchData = useCallback(async (revendedor) => {
    setLoading(true);
    const userData = JSON.parse(localStorage.getItem("authUser"));
    setNome(revendedor?.nome);
    setUsername(revendedor?.username);
    setEmail(revendedor?.email);
    setTelefone(revendedor?.telefone);
    setLimiteTestes((revendedor?.limite_teste || 0).toString());
    setLimiteRevendas((revendedor?.limite_usuarios || 0).toString());
    setValorCobrado(revendedor?.valor_cobrado);
    setObservacao(revendedor?.observacao);
    setLoading(false);
  }, []);

  useEffect(() => {
    const fecthAlldata = async (revendedor) => {
      const userData = apiClient.getLoggedinUser();
      setUserDados(userData);
      await fetchData(revendedor);
    };
    if (modalEditar && revendedor) {
      fecthAlldata(revendedor);
    }
  }, [modalEditar, fetchData]);
  return (
    <Modal
      isOpen={modalEditar}
      toggle={() => {
        setModalEditar(!modalEditar);
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalEditar(!modalEditar);
        }}
      >
        Editar Revendedor {revendedor?.nome}
        <br />
        <small className="text-muted">
          Desde {converterData(revendedor?.created)}
        </small>
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="username" className="form-label">
                  Usuário <span className="text-danger">*</span>
                </Label>
                <Input
                  id="username"
                  className="form-control"
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={() => {}}
                  readOnly
                />
                <small className="text-muted">
                  Nome de usuário não pode ser alterado
                </small>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="email" className="form-label">
                  E-mail
                </Label>
                <Input
                  id="email"
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => formatEmail(e)}
                  maxLength={100}
                  readOnly={userDados?.data?.id !== 1}
                />
              </div>
              {userDados?.data?.id !== 1 && (
                <small className="text-muted">
                  Apenas o próprio revenda pode alterar o seu e-mail.
                </small>
              )}
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="telefone" className="form-label">
                  Telefone
                </Label>
                <InputMask
                  mask="(99) 99999-9999"
                  placeholder="(11) 99999-9999"
                  defaultValue={telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="valorCobrado" className="form-label">
                  Valor Cobrado <span className="text-danger">*</span>
                </Label>
                <NumericFormat
                  className="form-control"
                  placeholder="0,00"
                  value={valorCobrado}
                  onValueChange={({ value }) =>
                    setValorCobrado(parseFloat(value))
                  }
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  prefix="R$ "
                  fixedDecimalScale={true}
                  allowNegative={false}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="limiteTestes" className="form-label">
                  Limite de Testes
                </Label>
                <Input
                  id="limiteTestes"
                  className="form-control"
                  type="text"
                  placeholder="Limite de Testes"
                  value={limiteTestes}
                  onChange={(e) =>
                    setLimiteTestes(e.target.value.replace(/\D/g, ""))
                  }
                  maxLength={8}
                />
                <small className="text-muted">Deixe 0 para ilimitado</small>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="limiteRevendas" className="form-label">
                  Limite de Revendas
                </Label>
                <Input
                  id="limiteRevendas"
                  className="form-control"
                  type="text"
                  placeholder="Limite de Revendas"
                  value={limiteRevendas}
                  onChange={(e) =>
                    setLimiteRevendas(e.target.value.replace(/\D/g, ""))
                  }
                  maxLength={8}
                />
                <small className="text-muted">Deixe 0 para ilimitado</small>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="observacao" className="form-label">
                  Observação
                </Label>
                <Input
                  id="observacao"
                  className="form-control"
                  type="textarea"
                  placeholder="Observação"
                  value={observacao}
                  onChange={(e) => setObservacao(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-info fw-medium"
              onClick={() => setModalEditar(!modalEditar)}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-info"
              onClick={() => editar()}
              disabled={loading}
            >
              Editar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
