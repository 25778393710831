import React, { useState, useEffect, useRef } from "react";
import { Input } from "reactstrap";
import FeatherIcon from "feather-icons-react";

const SearchOption = ({ setTermoBusca, termoBusca, setLoading }) => {
  const timeoutRef = useRef(null);

  const handleChange = (e) => {
    const value = e.target.value;
    setTermoBusca(value);
    
    // Limpa o timeout anterior se existir
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Define o loading e inicia a busca após 1 segundo
    setLoading(true);
    timeoutRef.current = setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // Limpa o timeout quando o componente é desmontado
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  
  return (
    <React.Fragment>
      <form className="app-search">
        <div className="position-relative">
          <Input
            type="text"
            className="form-control"
            placeholder="Buscar vídeos..."
            value={termoBusca}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          />
          <span className="search-widget-icon">
            <FeatherIcon icon="search" size={14} />
          </span>
          {termoBusca && (
            <span
              className="search-widget-icon search-widget-icon-close"
              onClick={() => {
                setTermoBusca("");
                setLoading(true);
                setTimeout(() => setLoading(false), 500);
              }}
              style={{ cursor: "pointer" }}
            >
              <FeatherIcon icon="x-circle" size={14} />
            </span>
          )}
        </div>
      </form>
    </React.Fragment>
  );
};

export default SearchOption;
