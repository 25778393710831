import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Input,
  Label,
  Spinner
} from "reactstrap";
import FeatherIcon from "feather-icons-react";

const ModalEdicao = ({ 
  isOpen, 
  toggle, 
  onSubmit, 
  loading, 
  formData, 
  onChange 
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        Editar Vídeo
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <div className="mb-3">
            <Label className="form-label" for="titulo">
              Título
            </Label>
            <Input
              type="text"
              id="titulo"
              name="titulo"
              value={formData.titulo}
              onChange={onChange}
              placeholder="Digite o título do vídeo"
            />
          </div>
          <div className="mb-3">
            <Label className="form-label" for="subtitulo">
              Subtítulo
            </Label>
            <Input
              type="textarea"
              id="subtitulo"
              name="subtitulo"
              value={formData.subtitulo}
              onChange={onChange}
              placeholder="Digite o subtítulo do vídeo"
              maxLength={700}
              rows={3}
            />
            <small className="text-muted">
              {formData.subtitulo?.length || 0}/700 caracteres
            </small>
          </div>
          <div className="mb-3">
            <Label className="form-label" for="url_youtube">
              URL do YouTube
            </Label>
            <Input
              type="text"
              id="url_youtube"
              name="url_youtube"
              value={formData.url_youtube}
              onChange={onChange}
              placeholder="Cole a URL do vídeo do YouTube"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            onClick={toggle}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner size="sm" className="me-2">
                  Carregando...
                </Spinner>
                Salvando
              </>
            ) : (
              <>
                <FeatherIcon icon="save" className="me-2" size={14} />
                Salvar
              </>
            )}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ModalEdicao;
