import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner
} from "reactstrap";
import FeatherIcon from "feather-icons-react";

const ModalConfirmacao = ({ isOpen, toggle, onConfirm, loading }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        Confirmar Exclusão
      </ModalHeader>
      <ModalBody>
        Tem certeza que deseja excluir este vídeo? Esta ação não pode ser desfeita.
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggle} disabled={loading}>
          Cancelar
        </Button>
        <Button color="danger" onClick={onConfirm} disabled={loading}>
          {loading ? (
            <>
              <Spinner size="sm" className="me-2">
                Carregando...
              </Spinner>
              Excluindo
            </>
          ) : (
            <>
              <FeatherIcon icon="trash-2" className="me-2" size={14} />
              Excluir
            </>
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalConfirmacao;
