import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";
import ModalCadastro from "./ModalCadastro";
import ModalEdicao from "./ModalEdicao";
import ModalConfirmacao from "./ModalConfirmacao";
import SearchOption from "./SearchOption";

const api = new APIClient();

const VideoTutoriais = () => {
  document.title = "Vídeo Tutoriais | Dark Store";
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [termoBusca, setTermoBusca] = useState("");

  // Estados para o modal de cadastro
  const [modalCadastro, setModalCadastro] = useState(false);
  const [cadastroLoading, setCadastroLoading] = useState(false);
  const [formCadastro, setFormCadastro] = useState({
    titulo: "",
    subtitulo: "",
    url_youtube: "",
  });

  // Estados para o modal de edição
  const [modalEdicao, setModalEdicao] = useState(false);
  const [edicaoLoading, setEdicaoLoading] = useState(false);
  const [formEdicao, setFormEdicao] = useState({
    id: null,
    titulo: "",
    subtitulo: "",
    url_youtube: "",
  });

  // Estados para o modal de confirmação
  const [modalExclusao, setModalExclusao] = useState(false);
  const [exclusaoLoading, setExclusaoLoading] = useState(false);
  const [videoParaExcluir, setVideoParaExcluir] = useState(null);

  const itensPorPagina = 12;

  useEffect(() => {
    const authUser = api.getLoggedinUser();
    setIsAdmin(authUser?.data?.id === 1);
  }, []);

  const fetchVideos = useCallback(async () => {
    setLoading(true);
    const data = {
      termo: termoBusca,
      itens_pagina: itensPorPagina,
      pagina: paginaAtual,
      order_by: "created_at",
      order: "desc",
    };

    const response = await api.post("/video-tutoriais/busca", data);

    if (response.sucesso) {
      setVideos(response.dados.data);
      setTotalPaginas(response.dados.last_page);
    } else {
      toast.error(response.mensagem || "Erro ao carregar os vídeos.");
    }
    setLoading(false);
  }, [termoBusca, paginaAtual]);

  useEffect(() => {
    fetchVideos();
  }, [fetchVideos]);

  useEffect(() => {
    if (termoBusca === "") {
      fetchVideos();
    }
  }, [termoBusca, fetchVideos]);

  const handleCadastroChange = (e) => {
    const { name, value } = e.target;
    setFormCadastro((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEdicaoChange = (e) => {
    const { name, value } = e.target;
    setFormEdicao((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCadastroSubmit = async (e) => {
    e.preventDefault();
    if (!formCadastro.titulo.trim() || !formCadastro.url_youtube.trim()) {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    setCadastroLoading(true);
    const response = await api.post("/video-tutoriais", formCadastro);

    if (response.sucesso) {
      toast.success(response.mensagem || "Vídeo cadastrado com sucesso!");
      setModalCadastro(false);
      setFormCadastro({ titulo: "", subtitulo: "", url_youtube: "" });
      fetchVideos();
    } else {
      toast.error(response.mensagem || "Erro ao cadastrar vídeo.");
    }
    setCadastroLoading(false);
  };

  const handleEdicaoSubmit = async (e) => {
    e.preventDefault();
    if (!formEdicao.titulo.trim() || !formEdicao.url_youtube.trim()) {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    setEdicaoLoading(true);
    const response = await api.put(`/video-tutoriais/${formEdicao.id}`, {
      titulo: formEdicao.titulo,
      subtitulo: formEdicao.subtitulo,
      url_youtube: formEdicao.url_youtube,
    });

    if (response.sucesso) {
      toast.success(response.mensagem || "Vídeo atualizado com sucesso!");
      setModalEdicao(false);
      setFormEdicao({ id: null, titulo: "", subtitulo: "", url_youtube: "" });
      fetchVideos();
    } else {
      toast.error(response.mensagem || "Erro ao atualizar vídeo.");
    }
    setEdicaoLoading(false);
  };

  const handleExclusao = async () => {
    if (!videoParaExcluir) return;

    setExclusaoLoading(true);
    const response = await api.delete(`/video-tutoriais/${videoParaExcluir}`);

    if (response.sucesso) {
      toast.success(response.mensagem || "Vídeo excluído com sucesso!");
      setModalExclusao(false);
      setVideoParaExcluir(null);
      fetchVideos();
    } else {
      toast.error(response.mensagem || "Erro ao excluir vídeo.");
    }
    setExclusaoLoading(false);
  };

  const iniciarEdicao = (video) => {
    setFormEdicao({
      id: video.id,
      titulo: video.titulo,
      subtitulo: video.subtitulo || "",
      url_youtube: video.url_youtube,
    });
    setModalEdicao(true);
  };

  const iniciarExclusao = (videoId) => {
    setVideoParaExcluir(videoId);
    setModalExclusao(true);
  };

  const renderPagination = () => {
    const pages = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, paginaAtual - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPaginas, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    pages.push(
      <PaginationItem key="first" disabled={paginaAtual === 1}>
        <PaginationLink first onClick={() => setPaginaAtual(1)}>
          <FeatherIcon icon="chevrons-left" size={15} />
        </PaginationLink>
      </PaginationItem>
    );

    pages.push(
      <PaginationItem key="prev" disabled={paginaAtual === 1}>
        <PaginationLink
          previous
          onClick={() => setPaginaAtual((prev) => prev - 1)}
        >
          <FeatherIcon icon="chevron-left" size={15} />
        </PaginationLink>
      </PaginationItem>
    );

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <PaginationItem key={i} active={paginaAtual === i}>
          <PaginationLink onClick={() => setPaginaAtual(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }

    pages.push(
      <PaginationItem key="next" disabled={paginaAtual === totalPaginas}>
        <PaginationLink next onClick={() => setPaginaAtual((prev) => prev + 1)}>
          <FeatherIcon icon="chevron-right" size={15} />
        </PaginationLink>
      </PaginationItem>
    );

    pages.push(
      <PaginationItem key="last" disabled={paginaAtual === totalPaginas}>
        <PaginationLink last onClick={() => setPaginaAtual(totalPaginas)}>
          <FeatherIcon icon="chevrons-right" size={15} />
        </PaginationLink>
      </PaginationItem>
    );

    return pages;
  };

  const getVideoId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Vídeo Tutoriais" pageTitle="Utilitários" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className="search-box flex-grow-1 me-2">
                      <SearchOption
                        setTermoBusca={setTermoBusca}
                        termoBusca={termoBusca}
                        setLoading={setLoading}
                      />
                    </div>
                    {isAdmin && (
                      <Button
                        color="primary"
                        onClick={() => setModalCadastro(true)}
                      >
                        <FeatherIcon icon="plus" className="me-2" size={16} />
                        Novo Vídeo
                      </Button>
                    )}
                  </div>

                  {loading ? (
                    <div className="text-center p-4">
                      <Spinner color="primary">Carregando...</Spinner>
                    </div>
                  ) : (
                    <>
                      <Row className="g-4">
                        {videos.length === 0 ? (
                          <Col xs={12}>
                            <div className="text-center text-muted">
                              Nenhum vídeo encontrado.
                            </div>
                          </Col>
                        ) : (
                          videos.map((video) => (
                            <Col key={video.id} lg={6} xl={4}>
                              <Card className="h-100">
                                <CardBody>
                                  <h5 className="card-title mb-3">
                                    {video.titulo}
                                  </h5>
                                  <div className="ratio ratio-16x9 mb-3">
                                    <iframe
                                      src={`https://www.youtube.com/embed/${getVideoId(
                                        video.url_youtube
                                      )}`}
                                      title={video.titulo}
                                      allowFullScreen
                                      className="rounded"
                                    ></iframe>
                                  </div>
                                  <small>{video.subtitulo}</small>
                                  {isAdmin && (
                                    <div className="text-end mt-2">
                                      <Button
                                        color="danger"
                                        size="sm"
                                        className="me-2"
                                        onClick={() =>
                                          iniciarExclusao(video.id)
                                        }
                                      >
                                        <FeatherIcon
                                          icon="trash-2"
                                          className="me-1"
                                          size={14}
                                        />
                                        Excluir
                                      </Button>
                                      <Button
                                        color="primary"
                                        size="sm"
                                        onClick={() => iniciarEdicao(video)}
                                      >
                                        <FeatherIcon
                                          icon="edit-2"
                                          className="me-1"
                                          size={14}
                                        />
                                        Editar
                                      </Button>
                                    </div>
                                  )}
                                </CardBody>
                              </Card>
                            </Col>
                          ))
                        )}
                      </Row>

                      {videos.length > 0 && (
                        <Row className="mt-4">
                          <Col>
                            <Pagination
                              className="pagination pagination-rounded justify-content-center"
                              listClassName="justify-content-center"
                            >
                              {renderPagination()}
                            </Pagination>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ModalCadastro
        isOpen={modalCadastro}
        toggle={() => setModalCadastro(false)}
        onSubmit={handleCadastroSubmit}
        loading={cadastroLoading}
        formData={formCadastro}
        onChange={handleCadastroChange}
      />

      <ModalEdicao
        isOpen={modalEdicao}
        toggle={() => setModalEdicao(false)}
        onSubmit={handleEdicaoSubmit}
        loading={edicaoLoading}
        formData={formEdicao}
        onChange={handleEdicaoChange}
      />

      <ModalConfirmacao
        isOpen={modalExclusao}
        toggle={() => setModalExclusao(false)}
        onConfirm={handleExclusao}
        loading={exclusaoLoading}
      />
    </React.Fragment>
  );
};

export default VideoTutoriais;
