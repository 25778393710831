import React, { useEffect, useState, useCallback } from "react";

import { Modal, ModalBody, ModalHeader, Input } from "reactstrap";
import Select from "react-select";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

import { useQuill } from "react-quilljs";

const ModalCompose = ({
  modal,
  toggle,
  setModal,
  revendedores,
  revendedor,
  setRefresh,
  loading,
}) => {
  const { quillRef } = useQuill();

  const [userDados, setUserDados] = useState({});
  const [destinatario, setDestinatario] = useState(null);
  const [assunto, setAssunto] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [assuntoSelect, setAssuntoSelect] = useState("");
  const [assuntos, setAssuntos] = useState([]);
  const [options, setOptions] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const reload = async () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const abrirTicket = async () => {
    setLoadingData(true);

    const data = {
      id_destinatario: parseInt(destinatario.value),
      assunto:
        assunto !== ""
          ? assunto
          : assuntos.find((item) => item.id === parseInt(assuntoSelect)).titulo,
      mensagem: quillRef.current.firstChild.innerHTML,
    };

    const response = await apiClient.post("/tickets", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setRefresh(true);
      setDestinatario(null);
      setAssunto("");
      setMensagem("");
      await reload();
      setModal(!modal);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoadingData(false);
  };

  const fetchAssuntos = useCallback(async () => {
    setLoadingData(true);

    const response = await apiClient.get(`/assunto-suporte`);
    if (response.sucesso) {
      setAssuntos(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoadingData(false);
  }, []);

  useEffect(() => {
    const executar = async () => {
      await fetchAssuntos();
      const usuario = JSON.parse(localStorage.getItem("authUser"));
      setUserDados(usuario);

      const optionsRevendedores = revendedores.map((revendedor) => ({
        label: `${revendedor?.nome} (${revendedor?.username})`,
        value: `${revendedor?.id}`,
      }));

      setOptions([
        {
          label: "Administrativo",
          options: [
            {
              label: `Fornecedor de Crédito (${usuario?.data?.parent?.username})`,
              value: `${usuario?.data?.parent_id}`,
            },
            { label: "Horizon", value: "1" },
          ],
        },
        {
          label: "Revendedores",
          options: optionsRevendedores,
        },
      ]);

      if (revendedor) {
        setDestinatario({
          label: `${revendedor?.nome} (${revendedor?.username})`,
          value: `${revendedor?.id}`,
        });
      }
    };

    executar();
  }, [revendedores]);

  return (
    <Modal
      id="composemodal"
      className="modal-lg"
      isOpen={modal}
      toggle={toggle}
      centered
    >
      <ModalHeader className="p-3 bg-light" toggle={toggle}>
        Novo Ticket de Suporte
      </ModalHeader>
      <ModalBody>
        <div>
          <div className="mb-3">
            {revendedor ? (
              <select className="form-control" disabled>
                <option value={revendedor.id}>
                  {revendedor.nome} ({revendedor.username})
                </option>
              </select>
            ) : (
              <Select
                value={destinatario}
                onChange={(sortBy) => {
                  setDestinatario(sortBy);
                }}
                options={options}
                id="destinatario"
                placeholder={
                  loadingData || loading
                    ? "Carregando..."
                    : "Selecione o destinatário"
                }
                disabled={loadingData || loading}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#1f2429",
                    border: "1px solid #2a4661",
                    color: "#aaa",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "#aaa",
                  }),
                }}
              />
            )}
          </div>

          {/* assuntos = [{id: 1, titulo: "Titulo do Assunto"}] */}

          <div className="mb-3">
            <Input
              type="select"
              className="form-control"
              placeholder="Assunto"
              value={assuntoSelect}
              onChange={(e) => {
                setAssuntoSelect(e.target.value);
                setAssunto("");
              }}
            >
              <option value="">Selecione o assunto</option>
              {assuntos.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.titulo}
                </option>
              ))}
              <option value="0">Outro</option>
            </Input>
          </div>

          {assuntoSelect === "0" && (
            <div className="mb-3">
              <Input
                type="text"
                className="form-control"
                placeholder="Assunto"
                value={assunto}
                onChange={(e) => {
                  setAssunto(e.target.value);
                }}
              />
            </div>
          )}
          {/* <div className="ck-editor-reverse">
            <CKEditor
              editor={ClassicEditor}
              config={{
                language: "pt-br",
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                ],
              }}
              onChange={(event, editor) => {
                setMensagem(editor.getData());
              }}
            />
          </div> */}
          <div className="snow-editor" style={{ height: 300 }}>
            <div ref={quillRef} />
          </div>
        </div>
      </ModalBody>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-ghost-danger"
          onClick={() => {
            setModal(false);
          }}
        >
          Descartar
        </button>

        <button
          type="button"
          className="btn btn-success"
          onClick={(e) => {
            e.preventDefault();
            abrirTicket(userDados);
          }}
          disabled={loadingData || loading}
        >
          Enviar
        </button>
      </div>
    </Modal>
  );
};

export default ModalCompose;
