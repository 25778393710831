import React from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalAviso = ({ modal, toggle, aviso }) => {
  const setMarcarLido = async (avisoId) => {
    if(!avisoId) return;
    const data = {
      aviso_id: avisoId,
    };

    const response = await apiClient.post("/avisos/marcar-lido", data);
    if (response.sucesso) {
      toggle();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        setMarcarLido(aviso?.id);
        toggle();
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setMarcarLido(aviso?.id);
          toggle();
        }}
      >
        Aviso de Painel
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <h3>{aviso?.assunto}</h3>
                <p
                  id="mensagem"
                  dangerouslySetInnerHTML={{ __html: aviso?.mensagem }}
                />
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className={`btn btn-link link-warning fw-medium`}
              onClick={() => {
                setMarcarLido(aviso?.id);
                toggle();
              }}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalAviso;
