import React, { useState, useEffect, useCallback } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Label,
  Input,
} from "reactstrap";

import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import ModalMercadoPago from "./ModalMercadoPago";
import { APIClient } from "../../helpers/api_helper";
import { checkLastLogin } from "../../Components/FunctionsRepository";

const apiClient = new APIClient();

const MeiosPagamento = ({ userDados }) => {
  const [idCliente, setIdCliente] = useState("");
  const [clienteSecret, setClienteSecret] = useState("");
  const [valorUsuario, setValorUsuario] = useState("");
  const [valorRevenda, setValorRevenda] = useState("");

  const [modalMercadoPago, setModalMercadoPago] = useState(false);

  const [loading, setLoading] = useState(false);

  const getMercadoPago = useCallback(async (id) => {
    setLoading(true);
    const response = await apiClient.get(`/mercado-pago/${id}/config`);
    if (response.sucesso) {
      setIdCliente(response.dados?.id_cliente || "");
      setClienteSecret(response.dados?.cliente_secret || "");
      setValorUsuario(response.dados?.valor_usuario || "");
      setValorRevenda(response.dados?.valor_revenda || "");
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (
          response.mensagem !== "" &&
          response.mensagem !== "Configuração não encontrada."
        )
          toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  }, []);

  const editar = async () => {
    setLoading(true);

    const data = {
      id_cliente: idCliente,
      cliente_secret: clienteSecret,
      valor_usuario: valorUsuario === "" ? 0 : parseFloat(valorUsuario),
      valor_revenda: valorRevenda === "" ? 0 : parseFloat(valorRevenda),
    };

    const response = await apiClient.put(
      `/mercado-pago/${userDados?.id}/config`,
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const limparConfig = async () => {
    setLoading(true);

    const response = await apiClient.delete(`/mercado-pago/${userDados?.id}`);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setIdCliente("");
      setClienteSecret("");
      setValorUsuario("");
      setValorRevenda("");
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const getData = async (id) => {
      await getMercadoPago(id);
    };
    if (userDados?.id) getData(userDados?.id);
  }, [userDados]);

  return (
    <>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            <b>Mercado Pago</b>
          </h4>
          <div className="flex-shrink-0">
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={() => setModalMercadoPago(true)}
            >
              Como configurar o Mercado Pago
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg="6" className="mt-3">
              <div className="form-group">
                <Label className="form-label">Public Key</Label>
                <Input
                  id="idCliente"
                  type="text"
                  value={idCliente}
                  onChange={(e) => setIdCliente(e.target.value)}
                  maxLength={200}
                />
              </div>
            </Col>
            <Col lg="6" className="mt-3">
              <div className="form-group">
                <Label className="form-label">Access Token</Label>
                <Input
                  id="clienteSecret"
                  type="password"
                  value={clienteSecret}
                  onChange={(e) => setClienteSecret(e.target.value)}
                  maxLength={200}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6" className="mt-3">
              <div className="form-group">
                <Label className="form-label">Valor Cliente</Label>
                <NumericFormat
                  id="valorUsuario"
                  placeholder="0,00"
                  className="form-control"
                  value={valorUsuario}
                  onValueChange={({ value }) => setValorUsuario(value)}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  prefix="R$ "
                  fixedDecimalScale={true}
                  allowNegative={false}
                />
              </div>
            </Col>
            <Col lg="6" className="mt-3">
              <div className="form-group">
                <Label className="form-label">Valor Revenda</Label>
                <NumericFormat
                  id="valorRevenda"
                  placeholder="0,00"
                  className="form-control"
                  value={valorRevenda}
                  onValueChange={({ value }) => setValorRevenda(value)}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  prefix="R$ "
                  fixedDecimalScale={true}
                  allowNegative={false}
                />
              </div>
            </Col>
          </Row>
          <Row>
            {" "}
            <div className="text-center mt-4">
              {(idCliente !== "" || clienteSecret !== "") && (
                <Button
                  type="button"
                  color="danger"
                  className="me-2"
                  onClick={() => limparConfig()}
                  disabled={loading}
                >
                  Limpar
                </Button>
              )}
              <Button
                type="button"
                color="success"
                onClick={() => editar()}
                disabled={loading}
              >
                Atualizar
              </Button>
            </div>
          </Row>
        </CardBody>
      </Card>
      <ModalMercadoPago
        modalMercadoPago={modalMercadoPago}
        setModalMercadoPago={setModalMercadoPago}
      />
    </>
  );
};

export default MeiosPagamento;
